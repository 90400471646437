import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styled from 'styled-components';
import { Heading } from 'legacy-ui';
import PageCollection from '../../components/PageCollection';
import PageCollectionItem from '../../components/PageCollectionItem';
import SEO from '../../components/SEO';
import Container from '../../sections/common/Container';
import Page from '../../sections/common/Page';
import RichText from '../../sections/common/RichText';
import { Localized } from '../../types/cms/generalTypes';
import { RentalOverviewCMSResult, RentalPageCMSResult } from '../../types/cms/rentalTypes';
import { LanguageKey } from '../../types/common';
import { SharedData } from '../../types/serverData';
import { loadFromCms, loadSharedDataFromCMS } from '../../utils/http';
import { createLanguagePaths, getRoute } from '../../utils/languages';
import { getOGImagesFromCMSData } from '../../utils/seo';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropertiesGrid, { PropertiesGridProps } from 'components/PropertiesGrid';
import { getPropertiesServerSide } from 'hooks/usePropertiesSearch';
import { SearchPropertiesResponse } from 'tenant-client';

const ENTRIES_PER_PAGE = 12;

const Title = styled.div`
  margin: 112px 0 48px 0;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    margin: 160px 0 56px 0;
  }
`;

interface RentalsProps extends SharedData {
  rentalOverview: RentalOverviewCMSResult;
  rentalPages: RentalPageCMSResult[];
  propertiesGridProps: PropertiesGridProps;
}

const Rentals = ({ headerData, generalData, footerData, rentalOverview, rentalPages, languagePaths, propertiesGridProps }: RentalsProps) => {
  const { pathname, locale } = useRouter();
  const [page, setPage] = useState(1);
  const currentPages = rentalPages.slice((page - 1) * ENTRIES_PER_PAGE, page * ENTRIES_PER_PAGE);
  const openGraphImages = getOGImagesFromCMSData(rentalOverview, generalData);
  const {
    properties: gridProperties,
    totalPropertiesCount: totalGridPropertiesCount,
    page: gridPage,
    columns,
    pageSize: gridPageSize,
  } = propertiesGridProps;
  const { t } = useTranslation('rentalOverview');

  return (
    <Page header={{ data: headerData }} footer={{ data: footerData, languagePaths }}>
      <SEO title={rentalOverview?.SEO.Title} description={rentalOverview?.SEO.Description} images={openGraphImages} languagePaths={languagePaths} />
      <Container>
        <div className="mb-20">
          <Title>
            <Heading size="l" tag="h1">
              {t('pageTitle')}
            </Heading>
          </Title>
          <PropertiesGrid
            properties={gridProperties}
            page={gridPage}
            pageSize={gridPageSize}
            columns={columns}
            totalPropertiesCount={totalGridPropertiesCount}
          />
        </div>
        <div className="text-center mb-14">
          <h2 className="font-bold text-4xl">{t('Popular cities')}</h2>
        </div>
        <PageCollection page={page} setPage={setPage} pageSize={ENTRIES_PER_PAGE} totalResults={rentalPages.length} generalTexts={generalData}>
          {currentPages?.map((item, index) => (
            <PageCollectionItem
              key={index}
              href={`${getRoute(pathname, locale as LanguageKey)}/${item.Slug}`}
              imageSrc={item.Image.data.attributes.url}
              imageAlt={`${rentalOverview.PreHeadline} ${item.PageTitle}`}
              heading={`${rentalOverview.PreHeadline} ${item.PageTitle}`}
            />
          ))}
        </PageCollection>

        <RichText fromCMS={rentalOverview.Content} spacing="noTop" />
      </Container>
    </Page>
  );
};

export default Rentals;

export async function getServerSideProps({ locale, query }: GetServerSidePropsContext) {
  try {
    const sharedDataPromise = loadSharedDataFromCMS(locale as LanguageKey);
    const rentalOverviewPromise = loadFromCms<Localized<RentalOverviewCMSResult>>('rental-overview', locale as LanguageKey, [
      'GetStarted.CTA',
      'SEO.OGImage',
    ]);
    const rentalPagesPromise = loadFromCms<RentalPageCMSResult[]>('rental-pages', locale as LanguageKey, ['Image'], false);
    const page = query.page ? Number(query.page) : 1;
    const propertiesResponsePromise = getPropertiesServerSide({}, page, 24);
    const translationPromise = serverSideTranslations(locale as LanguageKey);

    const [sharedData, rentalOverview, rentalPages, propertiesResponse, translation] = await Promise.all([
      sharedDataPromise,
      rentalOverviewPromise,
      rentalPagesPromise,
      propertiesResponsePromise,
      translationPromise,
    ]);

    const { headerData, footerData, generalData } = sharedData;
    const languagePaths = createLanguagePaths(rentalOverview, locale as LanguageKey);
    return {
      props: {
        headerData,
        footerData,
        generalData,
        rentalOverview,
        rentalPages,
        languagePaths,
        propertiesGridProps: { ...propertiesResponse, columns: 3 },
        ...translation,
      },
    };
  } catch (error) {
    return { notFound: true };
  }
}
